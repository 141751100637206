import { useEffect } from 'react';

// Components
import { ParagraphPlanet } from '@Components/Atomos/Titles';

// Images
import astronout from '@Assets/images/astro_buyer.png';

// Styles
import style from '@Sass/pages/venus.module.scss';

import { FaPlusCircle } from 'react-icons/fa';

/** VENUS BUYER INFO
 * page 6
 */
export const BuyerInfo = ({ setPage, setTitle }) => {
  useEffect(() => {
    setTitle('BUYER PERSONA');
  }, []);

  return (
    <div className={style.buyerInfo}>
      <section className={style.venusQuestions}>
        <ParagraphPlanet text="Dignissim commodo at arcu mattis mi purus. Nibh nec, nunc eu, vulputate. Non pellentesque at odio lectus vitae tortor a pharetra ligula." />
        <br></br>
        <button type="button" className={style.btnBuyer} onClick={() => setPage(7)}>
          CONTINUAR
        </button>
        <span className={style.addbuyer} onClick={() => setPage(8)}>
          <FaPlusCircle className={style.icon} /> <b>Agregar más Buyer Persona</b>
        </span>
      </section>
      <div className={style.astronaut}>
        <img src={astronout} alt="astronaut" />
      </div>
    </div>
  );
};
